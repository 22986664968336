import React, { memo, useCallback, useRef, useState } from "react";

import { useTranslation } from "next-i18next";

import { PromocodeInput } from "./PromocodeInput";
import { GET_CLIENT_CARD_TEMPLATE_DETAIL } from "../../../graphql/queries/queriesCard";
import { useLazyQuery } from "@apollo/client";
import { CLOSE_WHITE_SVG } from "../../../constants/svgConstants";
import { GET_CLIENT_EVENT_BY_PROFILE } from "../../../graphql/queries/queriesEvent";
import { useOnClickOutside } from "../../../hooks";

export const PromocodeBlock = memo(
  ({ hasOwnPromocode, promocode, setPromocode, setCardData = () => null, familyId, templateId, isSubscription, eventId }) => {
    const [t] = useTranslation();

    const [clientPromocode] = useState(promocode);

    const [{ errorMsg }, setState] = useState({
      errorMsg: "",
    });

    const inputRef = useRef("");
    const containerRef = useRef();

    const [getSubscription] = useLazyQuery(GET_CLIENT_CARD_TEMPLATE_DETAIL, {
      fetchPolicy: "cache-and-network",
    });

    const [getEvent] = useLazyQuery(GET_CLIENT_EVENT_BY_PROFILE, {
      fetchPolicy: "cache-and-network",
    });

    const onClick = useCallback(
      ({ isClose = false } = {}) => {
        setState({ errorMsg: "" });

        const value = inputRef.current?.value;

        if (isClose) {
          if (inputRef) inputRef.current.value = "";

          return;
        }
        // let isError = false;
        // if (isError) return setState({ errorMsg: t("required_field") });

        const promocodeValue = promocode ? "" : value.trim();

        if (isSubscription)
          return getSubscription({
            variables: {
              id: +templateId,
              ...(familyId && { profileId: +familyId }),
              promocode: promocodeValue,
            },
            onCompleted: ({ cardTemplate: cardData }) => {
              setPromocode(promocodeValue);

              setCardData({ cardData, isLoading: false });
            },
            onError: ({ graphQLErrors, message }) => {
              if (graphQLErrors?.[0]?.result === 0) setState({ errorMsg: message });
            },
          });

        getEvent({
          variables: {
            showPayments: true,
            eventId: +eventId,
            ...(familyId && { familyId: +familyId }),
            promocode: promocodeValue,
          },
          onCompleted: ({ event }) => {
            setPromocode(promocodeValue);
            setCardData({ data: event, isLoading: false });
          },

          onError: ({ graphQLErrors, message }) => {
            if (graphQLErrors?.[0]?.result === 0) setState({ errorMsg: message });
            // if (promocode) setPromocode(null);
          },
        });
      },
      [eventId, promocode, familyId, isSubscription]
    );

    const onBlur = useCallback(() => {
      setPromocode(clientPromocode);
    }, [clientPromocode]);

    useOnClickOutside(containerRef, onBlur);
    return (
      <>
        <div className="promocode">
          {promocode ? (
            <>
              <div className="promocode__text">
                {/* {promocode == "" ? (
                  <span>{`${t("Без промокоду")} ${promocode}`}</span>
                ) : (
                  <span>{`${t("your_promocode_is")} ${promocode}`}</span>
                )} */}
                <span>{`${t("your_promocode_is")} ${promocode}`}</span>
                {/* {!hasOwnPromocode?.current && ( */}
                <span className="promocode__remove-button" onClick={onClick}>
                  {CLOSE_WHITE_SVG}
                </span>
                {/* )} */}
              </div>
            </>
          ) : (
            <div ref={containerRef}>
              <PromocodeInput
                inputRef={inputRef}
                placeholderValue={t("enter_promocode")}
                errorMessage={errorMsg}
                classNameWrapper="promocode__input-wrapper"
                onClick={onClick}
                // onBlur={onBlur}
              />
              {errorMsg && (
                <div className="sign-up-section__input-error-message">
                  <p>{errorMsg}</p>
                </div>
              )}
            </div>
          )}
        </div>
        <style jsx global>{`
          @import "./PromocodeBlock.scss?1";
        `}</style>
      </>
    );
  }
);
